import axios from "axios";
let config = require('./../../../config/config')
import router from "@/router";
import i18n from "../../i18n";
export default {
    state: {
        qnaStatus: null,
        qnaKeyword: '',
        wrongAnswerCount: 0,
        court: '',
        faqCategory: ''
    },
    getters: {
        getQnaStatus: state => state.qnaStatus,
        getQnaKeyword: state => state.qnaKeyword,
        getWrongAnsCount: state => state.wrongAnswerCount,
        getCourt: state => state.court,
        getFaqCategory: state => state.faqCategory
    },
    mutations: {
        setQnaStatus(state, status) {
            state.qnaStatus = status;
        },
        setQnaKeyword(state, keyword) {
            state.qnaKeyword = keyword
        },
        setWrongAnswerCount(state, count) {
            state.wrongAnswerCount = count
        },
        setCourt(state, court) {
            state.court = court
        },
        setFaqCategory(state, category) {
            state.faqCategory = category
        }
    },
    actions: {
        requestQnA({ getters, dispatch, commit }, transcript) {
            // Request to QNA
            let data = JSON.stringify({
                "top": 3,
                "question": transcript,
                "includeUnstructuredSources": true,
                "confidenceScoreThreshold": "0.20"
            });
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://ars-studio-en.cognitiveservices.azure.com/language/:query-knowledgebases?projectName=' + getters.getQnaEndpointEN.kbName + '&api-version=2021-10-01&deploymentName=production',
                headers: {
                    'Ocp-Apim-Subscription-Key': 'da55d74bdec54b7b856aef2a8e8bbded',
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    const qnaAnalytics = {
                        timeStamp: new Date(),
                        kioskID: getters.getKioskId,
                        transcript: transcript,
                        result: response.data,
                    }
                    commit('setTouchRequest', {
                        module: 'QnA',
                        action: transcript,
                        response: response.data.answers[0].answer,
                        timeStamp: new Date(),
                        requestType: 'Speech'
                    })
                    console.log('Result: ', response.data.answers[0])
                    dispatch('sendQnaData', qnaAnalytics);
                    if (response.data.answers[0].confidenceScore > 0.6) {
                        dispatch('generateAnswer', response.data.answers[0])
                    } else if (response.data.answers[0].confidenceScore > 0.45) {

                        dispatch('avatarSpeak', 'I didn\'t quite get that, did you mean to ask following questions. Please tap your preference.')

                        let followUp = [];
                        response.data.answers.forEach(output => {
                            if (output.confidenceScore > 0.45)
                                followUp.push(output.questions[0])
                        });

                        dispatch('openOptionsViewer', {
                            dialogHeader: 'Please Tap the option you prefer',
                            dialogData: followUp,
                        })
                    }
                    else {
                        var mapPath = getters.getMaps.get(getters.getDefaultLocation.toLowerCase())
                        dispatch('openImageViewer', {
                            name: mapPath.mapName,
                            url: mapPath.mapImage
                        })
                        dispatch('avatarSpeak', i18n.t('defaultKBError'))
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        generateAnswer({ dispatch, commit, getters }, result) {
            console.log('Generate Answer: ', result.metadata.intent)
            commit('setQnaStatus', false)
            const metadata = result.metadata.intent
            const ansObj = result
            commit('setDefaultBubbleText', false)
            commit('closeOptionsViewer')
            switch (metadata) {
                case "navigate":
                    var menuName = ansObj.metadata.navigateto;
                    router.addRoute({ path: menuName.toLowerCase(), component: () => import('@/views' + menuName) });
                    if (router.currentRoute.path !== menuName.toLowerCase()) {
                        router.push(menuName.toLowerCase()).then(() => {
                            commit('setQnaKeyword', '')
                            dispatch('avatarSpeak', ansObj.answer);
                            let page = i18n.t('homepages').filter(page => page.pageType[0].pathName === menuName)
                            commit('setPageHeader', page[0].pageType[0].pageHeader)
                        })
                    } else {
                        commit('setQnaKeyword', '')
                        dispatch('avatarSpeak', ansObj.answer)
                    }
                    break;

                case "showmap":
                    try {
                        var mapPath = i18n.t('maps').filter(map => map.mapName.toLowerCase() === ansObj.metadata.mappath)
                        //var mapPath = getters.getMaps.get(ansObj.metadata.mappath)
                        console.log('Map Path', mapPath)
                        dispatch('openImageViewer', {
                            name: mapPath[0].mapName,
                            url: mapPath[0].mapImage
                        })
                        if (ansObj.answer !== 'default') {
                            dispatch('avatarSpeak', ansObj.answer + ' ' + mapPath[0].speech);
                        } else {
                            dispatch('avatarSpeak', mapPath[0].speech);
                        }

                    } catch (e) {
                        mapPath = getters.getMaps.get(getters.getDefaultLocation.toLowerCase())
                        dispatch('openImageViewer', {
                            name: mapPath.mapName,
                            url: mapPath.mapImage
                        })
                        dispatch('avatarSpeak', i18n.t('mapNotFound'))
                    }

                    break;

                case "speak":

                    dispatch('avatarSpeak', ansObj.answer);
                    break;

                case "showform":
                    try {
                        var formName = ansObj.metadata.formname
                        getters.getForms.forEach(form => {
                            if (form.formName.toLowerCase() === formName.value) {
                                console.log('Form Information', form)
                                if (form.documentType !== 'eforms') {
                                    dispatch('openPdfViewer', {
                                        url: form.document,
                                        name: form.formName,
                                        desc: 'Hello There',
                                        emailLink: form.documentUrl,
                                        type: form.documentType
                                    })
                                } else {
                                    dispatch('openEmailViewer', {
                                        pdfType: form.documentType,
                                        pdfName: form.formName,
                                        pdfUrl: form.documentUrl
                                    })
                                }

                                dispatch('avatarSpeak', ansObj.answer);
                            }
                        })
                    } catch (e) {
                        console.log(e.message)
                        mapPath = getters.getMaps.get(getters.getDefaultLocation.toLowerCase())
                        dispatch('openImageViewer', {
                            name: mapPath.mapName,
                            url: mapPath.mapImage
                        })
                        dispatch('avatarSpeak', i18n.t('formNotFound'));
                    }


                    break;

                case "showfaqcategory":
                    commit("setQnaKeyword", "")
                    var faqKeyword = ansObj.metadata.keyword

                    router.addRoute({ path: '/faqs', component: () => import('@/views/faqs') })
                    if (router.currentRoute.path !== '/faqs') {
                        router.push('/faqs')
                    }
                    var page = i18n.t('homepages').filter(page => page.pageType[0].pathName === 'faqs')
                    commit('setPageHeader', page[0].pageType[0].pageHeader)

                    var count = i18n.t('faqCategory').filter(cat => cat.categoryName.toLowerCase() === faqKeyword)
                    if (count.length === 0) {
                        commit("setQnaKeyword", faqKeyword)
                    } else {
                        commit('setSelectedFaqCat', { name: faqKeyword, type: 'expansionPanel' })
                    }
                    dispatch('avatarSpeak', ansObj.answer);
                    break;

                case "showformcategory":

                    var formKeyword = ansObj.metadata.keyword
                    var court = ansObj.metadata.court
                    router.addRoute({ path: '/forms', component: () => import('@/views/forms') })
                    if (router.currentRoute.path !== '/forms') {
                        router.push('/forms')
                    }
                    page = i18n.t('homepages').filter(page => page.pageType[0].pathName === 'forms')
                    commit('setPageHeader', page[0].pageType[0].pageHeader)
                    commit('setQnaKeyword', formKeyword)
                    commit('setCourt', court)
                    dispatch('avatarSpeak', ansObj.answer);
                    break;

                case "showformpackets":

                    var packetInfo = getters.getFormPacketsEnglish.get(ansObj.metadata.keyword)
                    dispatch('openPdfViewer', {
                        url: packetInfo.packetFiles,
                        name: packetInfo.packetName,
                        desc: packetInfo.packetDesc,
                        emailLink: packetInfo.packetLink,
                        type: 'Packet'
                    })
                    //dispatch('avatarSpeak', ansObj.answer);
                    break;

                case "showhours":
                    dispatch('openCourtTimingViewer', {})
                    dispatch('avatarSpeak', ansObj.answer);

                    break;

                case "session":
                    var action = ansObj.metadata.find(({ name }) => name === 'action').value
                    if (action === "still there") {
                        dispatch('kioskStillThere')
                    } else {
                        dispatch('resetKiosk')
                    }
                    break;

                case "followup":
                    console.log('Answer object at followup: ', ansObj)
                    var prompts = ansObj.dialog.prompts
                    var followUp = [];
                    console.log('How followup works: ', ansObj.answer, prompts)
                    dispatch('avatarSpeak', ansObj.answer);
                    prompts.forEach(item => followUp.push(item));
                    dispatch('openOptionsViewer', {
                        dialogHeader: i18n.t('selectOptionMsg'),
                        dialogData: followUp,
                    })
                    break;
                default:
                    mapPath = getters.getMaps.get(getters.getDefaultLocation.toLowerCase())
                    dispatch('openImageViewer', {
                        name: mapPath.mapName,
                        url: mapPath.mapImage
                    })
                    dispatch('avatarSpeak', i18n.t('kbError'))
                    break;
            }
        }

    }
}
