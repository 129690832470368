<template>
  <div>
    <v-row class="mt-3" no-gutters align="center" justify="center">
      <v-col cols="6">
        <v-card flat>
          <v-card-text class="d-flex justify-center pa-0">
            <v-text-field :placeholder="$i18n.t('placeHolder')" class="text-h5" hide-details outlined height="55px"
              rounded v-model="searchKeyword" @click="expand = !expand" :append-icon="searchBarIcon"
              clear-icon="mdi-close-circle" @click:append="searchKeyword = ''; expand = false">
            </v-text-field>
          </v-card-text>
          <v-expand-transition>
            <v-card-text class="d-flex justify-center ma-0 pa-0" v-if="expand">
              <SimpleKeyboard class="keyboard" @onKeyPress="onKeyPress" @onChange="onChange">
              </SimpleKeyboard>
            </v-card-text>
          </v-expand-transition>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-row no-gutters class="ma-2">
          <v-col cols="12" align="center" v-if="expand || searchKeyword !== ''">
            <v-card v-if="expand || searchKeyword !== ''" :ripple="false" class="mt-2 containerBorder overflow-y-auto"
              width="95%" flat :height="cardHeight">
              <v-card-text v-if="matchingForms.length === 0" class="pa-0">
                <span class="text-h4"> No matching forms found. </span>
              </v-card-text>
              <div v-else>
                <v-row no-gutters class="mt-6 ml-6">
                  <v-col cols="4" v-for="(form, i) in matchingForms" :key="i">
                    <v-card class="mt-2 mb-2 mx-1 PDFborder" flat @click="viewDoc(form, 'Form')" width="90%">
                      <v-row no-gutters class="fill-height" align="center">
                        <v-col cols="2" align="center">
                          <v-img src="@/assets/pdf.png" contain height="70"></v-img>
                        </v-col>
                        <v-col cols="10">
                          <v-card-title class="text-subtitle-1 font-weight-bold justify-center">
                            {{ form.formNumber }}
                          </v-card-title>
                          <v-card-text class="text-subtitle-1 font-weight-bold justify-center">
                            {{ form.formName | trimLength }}
                          </v-card-text>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12" v-else>
            <v-row>
              <v-col cols="3" class="mt-5" align="center">
                <v-card width="95%" class="containerBorder pa-0 ma-0" color="primary" height="100%">
                  <v-list class="overflow-y-auto" height="100%">
                    <v-list-item v-for="(cat, i) in $t('formCategory')" :key="i">
                      <v-list-item-content>
                        <v-btn height="45" @click="$store.commit('setSelectedFormCat', { category: cat.categoryType });"
                          v-bind:color="getSelectedFormCat.category.toLowerCase() === cat.categoryType.toLowerCase() ? '#C62828' : 'primary'"
                          class="white--text">{{
                            cat.categoryName
                          }}</v-btn>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>

              <v-col cols="9" class="mt-5" align="center">

                <v-card width="95%" class="containerBorder" :height="cardHeight" v-if="getCourts.length > 1 & showTab">
                  <v-tabs v-model="tab">
                    <v-tab v-for="(categoryType, i) in getCourts" :key="i"> {{ categoryType }} Court
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tab">
                    <v-tab-item v-for="(categoryType, i) in getCourts" :key="i">
                      <v-card class="overflow-y-auto" flat :height="cardHeight - 60">
                        <v-row no-gutters class="mt-6 ml-6" v-if="getSelectedForms(categoryType).length > 0">
                          <v-col cols="4" v-for="(form, i) in getSelectedForms(categoryType)" :key="i">
                            <v-card class="mt-2 mb-2 mx-1 PDFborder overflow-y-hidden" flat @click="viewDoc(form, 'Form')"
                              width="90%" height="110">
                              <v-row no-gutters class="fill-height" align="center">
                                <v-col cols="2" align="center">
                                  <v-img src="@/assets/pdf.png" contain height="70"></v-img>
                                </v-col>
                                <v-col cols="10" align-self="center" class="pt-3">
                                  <v-card-title class="text-subtitle-1 font-weight-bold justify-center pa-0 ma-0">
                                    {{ form.formNumber }}
                                  </v-card-title>
                                  <v-card-text class="text-subtitle-1 justify-center">
                                    {{ form.formName | trimLength }}
                                  </v-card-text>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row v-else class="fill-height" no-gutters>
                          <v-col cols="12" align-self="center" align="center" class="text-h6">
                            {{ $t('noFormText') }}
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card>
                <v-card v-else width="95%" class="containerBorder" :height="cardHeight">
                  <v-card class="overflow-y-auto" flat :height="cardHeight - 10">
                    <v-row no-gutters class="mt-6 ml-6" v-if="getSelectedFormCat.category !== 'Packets'">
                      <v-row v-if="getSelectedForms('Filtered').length > 0" no-gutters>
                        <v-col cols="4" v-for="(form, i) in getSelectedForms('Filtered')" :key="i">
                          <v-card class="mt-2 mb-2 mx-1 PDFborder overflow-y-hidden" flat @click="viewDoc(form)"
                            width="90%" height="110">
                            <v-row no-gutters class="fill-height" align="center">
                              <v-col cols="2" align="center">
                                <v-img src="@/assets/pdf.png" contain height="70"></v-img>
                              </v-col>
                              <v-col cols="10" align-self="center" class="pt-3">
                                <v-card-text class="text-subtitle-1 justify-center font-weight-bold">
                                  {{ form.formName | trimLength }}
                                </v-card-text>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row v-else no-gutters class="fill-height">
                        <v-col align-self="center" class="text-h5" cols="12">
                          No Forms to display the Magistrate Court. <br><br> Please go the Service Window for more
                          Information.
                        </v-col>
                        <v-col cols="12" class="mt-16">
                          <v-btn width="300" height="50" color="primary" outlined
                            @click="$store.dispatch('openImageViewer', { url: getDefaultLocation })"><v-icon
                              left>mdi-map-marker</v-icon>W8 -
                            Service
                            Window</v-btn>
                        </v-col>
                      </v-row>
                    </v-row>
                    <!-- To display form Packets -->
                    <v-row no-gutters v-if="getSelectedFormCat.category === 'Packets'" class="mt-6 ml-6">
                      <v-row v-if="getSelectedForms('Packets').length > 0" no-gutters>
                        <v-col v-for="(item, i) in getSelectedForms('Packets')" :key="i" cols="4">
                          <v-card class="mt-2 mb-2 mx-1 PDFborder" flat width="90%" height="110" @click="viewDoc(item)">
                            <v-row no-gutters class="fill-height" align="center">
                              <v-col cols="2">
                                <v-img src="@/assets/pdf.png" contain height="70"></v-img>
                              </v-col>
                              <v-col cols="10" align="center">
                                <span class="text-subtitle-1 font-weight-bold">
                                  {{ item.packetName | trimLength }}
                                </span>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row v-else no-gutters class="fill-height">
                        <v-col align-self="center" class="text-h5" cols="12">
                          No Packets to display the Magistrate Court. <br><br> Please go the Service Window for more
                          Information.
                        </v-col>
                        <v-col cols="12" class="mt-16">
                          <v-btn width="300" height="50" color="primary" outlined
                            @click="$store.dispatch('openImageViewer', { url: getDefaultLocation })">
                            <v-icon left>mdi-map-marker</v-icon>W8 - Service
                            Window</v-btn>
                        </v-col>
                      </v-row>
                    </v-row>
                  </v-card>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import SimpleKeyboard from "@/components/SimpleKeyboard";

export default {
  name: 'forms',
  data() {
    return {
      tab: 0,
      searchKeyword: '',
      searchBarIcon: 'mdi-magnify',
      expand: false,
      cardHeight: 650,
      showTab: true
    }
  },
  components: {
    SimpleKeyboard
  },
  computed: {
    ...mapGetters(['getFormType', 'getQnaKeyword', 'getFormCatListHeight', 'getSelectedFormCat', 'getCurrentLanguage', 'getForms', 'getCourts', 'getDefaultLocation']),
    matchingForms() {
      let temp = []
      this.$i18n.t('forms').forEach(form => {
        if (form.formName.toLowerCase().includes(this.searchKeyword.toLowerCase())) {
          temp.push(form)
        }
      })
      return temp
    }
  },
  watch: {
    expand(newVal) {
      if (newVal) {
        this.searchBarIcon = 'mdi-close-circle'
        this.cardHeight = 380;
      } else {
        this.searchBarIcon = 'mdi-magnify'
        this.cardHeight = 650
      }
    },
    getQnaKeyword(newVal) {
      this.tab = 0;
      if (this.getCourt === 'district court') {
        this.$store.commit('setCourt', 'District Court')
      } else {
        this.$store.commit('setCourt', 'Magistrate Court')
      }
      let types = this.getCourtType.get(this.getCourt)
      types.forEach(type => {
        if (type.categoryName.toLowerCase() === newVal) {
          this.displayFolderContent(type.categoryName, type.categoryType.toLowerCase())
        }
      })
    },
  },
  methods: {
    onChange(input) {
      this.searchKeyword = input;
    },
    onKeyPress(button) {
      if (button === "{bksp}")
        this.searchKeyword = this.searchKeyword.slice(0, -1);
    },
    viewDoc(doc, docType) {
      console.log('Clicked Document : ', doc)
      if (doc.docType === 'Packet') {
        this.$store.dispatch('openPdfViewer', {
          url: doc.packetFiles,
          name: doc.packetName,
          emailLink: doc.packetLink,
          type: doc.docType
        })
      } else {
        this.$store.dispatch('openPdfViewer', {
          url: doc.formPdf,
          name: doc.formName,
          emailLink: doc.pdfUrl,
          type: doc.docType
        })
      }


      // Touch Usage
      this.$store.commit('setTouchRequest', {
        module: 'Forms',
        action: 'Form',
        response: doc.formNumber + ' - ' + doc.formName,
        timeStamp: new Date(),
        requestType: 'Touch'
      })
    },
    getSelectedForms(court) {
      let temp = []
      switch (this.getSelectedFormCat.category) {
        case "All":
          // When All forms are to be displayed on the kiosk screen
          this.showTab = false
          this.$i18n.t("forms").forEach(form => {
            temp.push(form)
          })
          break;

        case "Packets":
          // When Packets are to be displayed
          this.showTab = false
          temp = this.$i18n.t("formPackets")
          break;

        case "English":
          // This condition will be used to display English forms when Spanish Language is selected
          this.showTab = false
          this.$store.getters.getForms.forEach(form => {
            if (form.locale === 'en') {
              temp.push(form)
            }
          })
          break;

        case "Bilingual":
          // This condition will be used to display Bilingual forms when English Language is selected
          this.showTab = false
          this.$store.getters.getForms.forEach(form => {
            if (form.locale === 'es-US') {
              temp.push(form)
            }
          })
          break;

        default:
          this.$i18n.t("forms").forEach(form => {
            if (form.formCategory.toLowerCase().includes(this.getSelectedFormCat.category.toLowerCase())) {
              temp.push(form)
            }
          })
          if (this.getCourts.length > 1) {
            this.showTab = true
            temp = temp.filter(form => form.court === court)
          } else {
            this.showTab = false
          }
          break;
      }
      console.log('Length of Temp: ', temp.length)
      return temp;
    },
  },
  filters: {
    trimLength(val) {
      if (val.length < 50) {
        return val
      }
      return `${val.substring(0, 60)}...`
    }
  },
}
</script>

<style>
.formfolder {
  border-radius: 15px !important;
}
</style>
<i18n>
  {
    "en": {
        "packets": "Form Packets",
        "englishForms": "English Forms",
        "allForms": "All Forms",
        "placeHolder": "Enter name/number to search for forms",
        "spanishForms": "Bilingual Forms",
        "noFormText": "These forms are not used in Magistrate Court"
      },
    "es":{
        "packets": "Paquetes de Formulario",
        "englishForms": "Formularios en inglés",
        "allForms": "Todas las Formas",
        "placeHolder": "Ingrese nombre/número para buscar formularios",
        "spanishForms": "Bilingual Forms",
        "noFormText": "Estos formularios no son usados por el tribunal de primeras instancias"
      }
  }
</i18n>
<style>
.tabClass {
  background-color: #15306B;
  color: white !important;
}
</style>