
module.exports = {
  firebase:{
    apiKey: "AIzaSyAq9vvHg9oLFDs96GQJzeaNeat35PfIfho",
    authDomain: "lancing-mi.firebaseapp.com",
    databaseURL: "https://lancing-mi-default-rtdb.firebaseio.com",
    projectId: "lancing-mi",
    storageBucket: "lancing-mi.appspot.com",
    messagingSenderId: "735657454872",
    appId: "1:735657454872:web:0f9720af1092a2a9aa026d",
    measurementId: "G-R4P1P45ZZX"
  }
}

