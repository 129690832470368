import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from './store'
Vue.use(VueI18n)

let getHomepageEn = () => {
  return store.getters.getHomepage.filter(page => page.locale === "en")
}

let getHomepageEs = () => {
  return store.getters.getHomepage.filter(page => page.locale === "es-US")
}

let getFaqCategoriesEn = () => {
  console.log('Faq Categories: ', store.getters.getFaqCategories)
  return store.getters.getFaqCategories.filter(cat => cat.locale === "en")
}

let getFaqCategoriesEs = () => {
  return store.getters.getFaqCategories.filter(cat => cat.locale === "es-US")
}

let getFaqsEn = () => {
  return store.getters.getFaqs.filter(faq => faq.locale === "en")
}

let getFaqsEs = () => {
  return store.getters.getFaqs.filter(faq => faq.locale === "es-US")
}

let getMapsEn = () => {
  let temp = []
  store.getters.getMaps.forEach(map => {
    if (map.locale === 'en') {
      temp.push(map)
    }
  })
  return temp
}

let getMapsEs = () => {
  let temp = []
  store.getters.getMaps.forEach(map => {
    if (map.locale === 'es-US') {
      temp.push(map)
    }
  })
  return temp
}

let getFloorsEn = () => {
  return store.getters.getFloors.filter(cat => cat.locale === "en")
}
let getFloorsEs = () => {
  return store.getters.getFloors.filter(cat => cat.locale === "es-US")
}

let getDepartments = () => {
  return store.getters.getDepartment
}
export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: {
    en: {
      // Main Page
      homepages: getHomepageEn,

      // Faqs
      faqCategory: getFaqCategoriesEn,
      faqs: getFaqsEn,

      departments: getDepartments,

      // Maps
      maps: getMapsEn,
      floors: getFloorsEn,
      kbError: "Sorry I'm still learning. Please ask me a different question or, get information about this topic at the Circuit Court Clerks office located here.",
      defaultKBError: "Sorry, I'm not sure how to answer this question. Please ask me a different question, or you can continue using the touch interface. Also, you can get more information at the Circuit Court Clerks office located here.",
      mapNotFound: "Sorry, I can't find the requested location, please go to the Circuit Court Clerks office for more information.",
      formNotFound: "Sorry, I can't find the requested form, please go to the Circuit Court Clerks office for more information.",
      selectOptionMsg: "Tap on the below, or click on the microphone to ask another question."
    },
    es: {
      // Main Page
      homepages: getHomepageEs,

      // Faqs
      faqCategory: getFaqCategoriesEs,
      faqs: getFaqsEs,

      departments: getDepartments,

      // Maps
      maps: getMapsEs,
      floors: getFloorsEs,
      kbError: "Sorry I'm still learning. Please ask me a different question or, get information about this topic at the Law Library located here.",
      defaultKBError: "Sorry, I'm not sure how to answer this question. Please ask me a different question, or you can continue using the touch interface. Also, you can get more information at the Law Library located here.",
      mapNotFound: "Sorry, I can't find the requested location, please go to the Law Library for more information.",
      formNotFound: "Sorry, I can't find the requested form, please go to the Law Library for more information.",
      selectOptionMsg: "Tap on the below, or click on the microphone to ask another question."
    }
  }
})
