<template>
  <v-dialog v-model="getSessionEndViewer" @click:outside="$store.commit('setSessionEndViewer', false)" width="67%"
    persistent>
    <v-card class="pa-5">
      <v-card-title class="justify-center text-h4 pb-7 font-weight-bold">
        {{ $t('header') }}
      </v-card-title>
      <v-card-text align="center" class="text-h4 font-weight-bold black--text">
          {{ $t('endIn') }} {{ countDown }} {{ $t('unit') }}
        </v-card-text>
      <v-card-text align="center" class="pa-0">
        <v-card class="my-1" width="85%" @click="viewElement($i18n.t('option1'))" color="primary">
          <v-card-text class="white--text text-h5">
            {{ $t('option1') }}
          </v-card-text>
        </v-card>
        <v-card class="my-1" width="85%" @click="viewElement($i18n.t('option2'))" color="primary">
          <v-card-text class="white--text text-h5">
            {{ $t('option2') }}
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "sessionEndViewer",
  data() {
    return {
      talkButtonSrc: "talkButton.png",
      header: "Are you still there?",
      options: ["I'm still here", "Start Over"],
      countDown: 15,
    };
  },
  computed: {
    ...mapGetters([
      "getIsSpeaking",
      "speechRecognitionStatus",
      "getQnaStatus",
      "getResetKioskStatus",
      "getSessionEndViewer",
      "getCompleteUserSession",
      "getResetKioskStatus",
    ]),
  },
  //   created() {
  //       this.countDownTimer()
  //   },
  methods: {
    viewElement(input) {
      // Touch Usage
      this.$store.commit("setTouchRequest", {
        module: "Session End Viewer",
        action: input,
        response: "Action executed on touch",
        timeStamp: new Date(),
        requestType: "Touch",
      });
      if (input === this.$i18n.t('option1')) {
        this.$store.dispatch("kioskStillThere");
      } else if (input === this.$i18n.t('option2')) {
        this.$store.dispatch("kioskStartOver");
      }
    },
    closeDialog() {
      this.$store.commit("setSessionEndViewer", false);
      this.$store.dispatch("kioskStillThere");
      window.stopSpeech();
    },
    actionOnTap() {
      console.log("Is Speaking", this.getResetKioskStatus);
      if (!this.getIsSpeaking) {
        if (this.getResetKioskStatus) {
          this.$store.commit("setSpeechRecognitionHandler", true);
        } else {
          this.$store.commit("setSpeechRecognitionHandler", true);
        }
      } else {
        window.stopSpeech();
        this.talkButtonSrc = "talkButton.png";
      }
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.countDown = 15;
      }
    },
  },
  watch: {
    getIsSpeaking(newVal) {
      if (newVal) {
        this.talkButtonSrc = "stopTalking.png";
      }
      if (!newVal && !this.speechRecognitionStatus) {
        this.talkButtonSrc = "talkButton.png";
      }
    },
    speechRecognitionStatus(newVal) {
      if (newVal) {
        this.talkButtonSrc = "talkButtonActive.webp";
      } else {
        this.talkButtonSrc = "talkButton.png";
      }
    },
    getSessionEndViewer(newVal) {
      if (newVal) {
        this.countDownTimer();
      }
    },
  },
  mounted() {
    //this.$store.dispatch('avatarSpeak', this.$i18n.t('sessionEndMessage'))
  }
};
</script>

<style scoped>
.talk-button {
  filter: sepia(19%) saturate(697%) hue-rotate(294deg) brightness(95%) contrast(350%);
}
</style>

<i18n>
  {
    "en": {
        "sessionEndMessage": "Are you still there?",
        "header": "Are you still there?",
        "option1": "I'm still here", 
        "option2": "Start Over",
        "endIn": "Your session will timeout in ",
        "unit":  " seconds"
      },
    "es":{
        "sessionEndMessage": "¿Sigues ahí?",
        "header": "¿Sigues ahí?",
        "option1": "Todavía Estoy Aquí", 
        "option2": "Comenzar de Nuevo",
        "endIn" : "Su sesión expirará en ",
        "unit":  " segundos"
      }
  }
</i18n>
