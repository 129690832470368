<template>
    <v-row align="center" justify="center">
        <v-col cols="9">
            <v-card flat height="700" class="containerBorder">
                <v-card class="overflow-y-auto overflow-x-hidden" height="98%" flat>
                    <v-row no-gutters class="justify-center">
                        <v-col cols="12" v-for="(depart, i) in departments" :key="i">
                            <v-divider class="mt-5 mx-5" v-if="i !== 0"></v-divider>
                            <h1 class="black--text pl-5 pt-8">{{ depart }}</h1>
                            <div v-for="(info, i) in departmentsInfo" :key="i" class="mt-1">
                                <div v-if="info.contactDepartment === depart">
                                    <v-row class="pl-10">
                                        <v-col cols="9">
                                            <h3 class="black--text"> {{ info.contactName }} </h3>
                                        </v-col>
                                        <v-col cols="2">
                                            <h2> {{ info.contactNumber }}</h2>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: 'directory',
    computed: {
        departmentsInfo() {
            const temp = []
            this.$i18n.t("departments").forEach(depart => {
                console.log('Department: ', depart)
                depart.forEach(dep => {
                    temp.push(dep)
                })
            })
            return temp
        },
        departments() {
            console.log('Department Keys: ', this.$i18n.t("departments").keys())
            return this.$i18n.t("departments").keys()
        }
    }
}
</script>