<template>
  <v-dialog width="75%" height="800" @click:outside="closeDialog" v-model="getPdfViewer.show" class="overflow-x-hidden">
    <v-card class="pa-2">
      <v-row no-gutters>
        <v-col cols="11"><v-card-title class="justify-center headline font-weight-bold text-center">
            {{ getPdfViewer.name }} {{ getPdfViewer.type }}
          </v-card-title>
        </v-col>
        <v-col cols="1" align="right" justify="right">
          <v-btn icon @click="closeDialog" class="mr-2 mt-2">
            <v-icon color="red" x-large>mdi-window-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="!error">
        <v-col cols="1" align-self="center">
          <v-icon v-if="currentPdf !== 0" @click="pdfCycle('previous')" :disabled="loading" color="primary"
            size="80">
            mdi-chevron-left-box
          </v-icon>
        </v-col>
        <v-col align="center" cols="10" align-self="center">
          <v-card v-if="loading" height="570" flat>
            <v-row class="fill-height">
              <v-col cols="12" align-self="center">
                <v-card-actions class="justify-center">
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
          <div v-else class="scroll" id="scrollID" style="height: 570px;">
            <pdf v-for="i in numOfPages" :key="i" :page="i" :src="pdfUrl" ref="pdf" id="pdf1" width="100%"
              @error="errorOccured" @loaded="progressPageLoad">
              <canvas></canvas>
            </pdf>
          </div>
          {{ currentPdf + 1 }}/{{ numOfPdf }}
        </v-col>
        <v-col cols="1" align-self="center">
          <v-icon v-if="currentPdf !== numOfPdf - 1" @click="pdfCycle('next')" :disabled="loading" color="primary"
            size="80">
            mdi-chevron-right-box
          </v-icon>
        </v-col>
        <v-col cols="12" justify="center" align="center">
          <!-- <v-btn width="350" color="primary" x-large class="ma-2 text-h5" @click="pickUp()">
            {{ $t('pickupButton') }}
          </v-btn> -->
          <v-btn width="350" color="primary" x-large class="ma-2 text-h5" @click="sendEmail()">
            {{ $t('emailButton') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-else no-gutters class="mr-16">
        <v-col align-self="center" cols="12" justify="center" align="center">
          <span class="error--text text-h6"> <v-icon color="error">mdi-alert-circle-outline</v-icon> Error while loading
            pdf </span>
        </v-col>
        <v-col cols="12" justify="center" align="center">
          <v-btn width="350" color="primary" x-large class="ma-2 text-h5" @click="reload()">
            {{ $t('relaodPdf') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import pdf from 'vue-pdf'
import { mapGetters } from 'vuex'
let config = require('./../../../config/config')

export default {
  name: 'pdfViewer',
  data() {
    return {
      loadingTask: null,
      numOfPages: null,
      pdfUrl: null,
      numOfPdf: 1,
      currentPdf: 0,
      disableButton: false,
      pageCount: 0,
      error: false,
      loading: false
    }
  },
  components: {
    pdf
  },
  computed: {
    ...mapGetters(['getPdfViewer', 'getDefaultLocation'])
  },
  methods: {
    viewPdf(url) {
      this.pdfUrl = url;
      this.loadingTask = pdf.createLoadingTask(url)
      this.loadingTask.promise
        .then(pdf => {
          this.numOfPages = pdf.numPages;
        })
    },
    pdfCycle(direction) {
      //this.disableButton = true
      //document.scrollTo(0, 0)
      this.loadingTask = null
      this.pdfUrl = null
      this.numOfPages = 1
      this.loading = true
      
      if (direction === 'next') {
        if (this.currentPdf === this.numOfPdf - 1) this.currentPdf = 0;
        setTimeout(() => { this.viewPdf(this.getPdfViewer.url[++this.currentPdf]); this.loading = false; document.getElementById("scrollID").scrollTop = 0 }, 2000)
      } else {
        if (this.currentPdf === 0) this.currentPdf = this.numOfPdf - 1;
        setTimeout(() => { this.viewPdf(this.getPdfViewer.url[--this.currentPdf]); this.loading = false; document.getElementById("scrollID").scrollTop = 0 }, 2000)
      }
    },
    closeDialog() {
      this.numOfPages = null;
      this.numOfPdf = 1;
      this.pdfUrl = null;
      this.currentPdf = 0;
      this.$store.commit('closePdfViewer');
      window.stopSpeech();
      this.error = false
      this.loadingTask = null
    },
    reload() {
      this.numOfPages = null;
      this.error = false
      console.log('PDF Info: ', this.getPdfViewer)
      this.viewPdf(this.getPdfViewer.url[this.currentPdf])
    },
    errorOccured() {
      console.log('Error while loading pdf')
      this.error = true
    },
    progressPageLoad() {
      console.log('Progress of Page Load', this.numOfPages)
      this.pageCount = this.pageCount + 1
      if (this.numOfPages === this.pageCount) {
        this.disableButton = false
        this.pageCount = 0
      } else {
        //this.disableButton = true
      }
    },
    pickUp() {
      this.$store.dispatch('openImageViewer', {
        url: this.getDefaultLocation
      })
      this.$store.commit('closePdfViewer')
      this.$store.dispatch('avatarSpeak', this.$i18n.t('pickupText'))
    },
    sendEmail() {
      this.$store.dispatch('openEmailViewer', {
        pdfType: this.getPdfViewer.type,
        pdfName: this.getPdfViewer.name,
        pdfUrl: this.getPdfViewer.emailLink
      })
      this.$store.commit('closePdfViewer')
    }
  },
  watch: {
    getPdfViewer(newVal) {
      if (newVal.show) {
        let speech = '';
        switch (this.getPdfViewer.type.toLowerCase()) {
          case 'form':
            this.viewPdf(this.getPdfViewer.url)
            speech = this.$i18n.t("formText");
            break;
          case 'packet':
            this.viewPdf(this.getPdfViewer.url[this.currentPdf])
            this.numOfPdf = this.getPdfViewer.url.length;
            speech = this.$i18n.t("packetText")
            break;
          case 'guide':
            this.viewPdf(this.getPdfViewer.url)
            speech = this.$i18n.t("guideText");
            break;
          default:
            break;
        }
        this.$store.dispatch('avatarSpeak', speech)
      }
    }
  }
}
</script>
<style>
.scroll {
  overflow-y: auto
}

.scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #d37444 !important;
  width: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px #d37444 !important;
}
</style>
<i18n>
  {
    "en": {
        "formText": "You can find this form in the form rack, or I'll email you the form.",
        "packetText": "You can pick-up this packet for a fee, or I'll email you the packet for free. Please select your choice.",
        "guideText": "You can pick-up this guide for a fee, or I'll email you the guide for free. Please select your choice.",
        "pickupText": "Pick up this form for a fee at the Clerk's office, circled in red.",
        "emailButton": "Email",
        "pickupButton": "Pick Up",
        "relaodPdf" : "Reload"
      },
    "es":{
        "formText": "Puede escoger este formulario si paga un cargo, o se lo puedo enviar gratis a su correo electrónico. Seleccione su opción.",
        "packetText": "Puede escoger este paquete si paga un cargo, o se lo puedo enviar gratis a su correo electrónico. Seleccione su opción.",
        "guideText": "Puede escoger esta guía si paga un cargo, o se la puedo enviar gratis a su correo electrónico. Seleccione su opción.",
        "pickupText": "Escoja este formulario si paga un cargo en la oficina del secretario, está marcado con un círculo rojo.",
        "emailButton": "Correo Electrónico",
        "pickupButton": "Pasar a Buscar",
        "relaodPdf" : "Recargar"
      }
  }
</i18n>